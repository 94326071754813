import { Text, Image, Container, Stack, SimpleGrid } from "@chakra-ui/react";
import LlamaIndexLogo from "../assets/companies/llamaindex.png";
import FundriseLogo from "../assets/companies/fundrise.svg";
import GradioLogo from "../assets/companies/gradio.svg";
import PulleyLogo from "../assets/companies/pulley.png";
import HearthLogo from "../assets/companies/hearth.svg";

const data = [
    { logo: FundriseLogo, name: "Fundrise", brightness: 3 },
    { logo: PulleyLogo, brightness: 1 },
    { logo: HearthLogo, brightness: 1 },
    { logo: GradioLogo, brightness: 1.5 },
    { logo: LlamaIndexLogo, name: "LlamaIndex", brightness: 1 },
];

export default function Users() {
    return (
        <Container maxWidth={"5xl"} px={4} mt={"6rem"}>
            <Stack alignItems="stretch" width="100%" overflow="hidden">
                <Text
                    fontSize={{ base: "2xl", md: "3xl" }}
                    textAlign={{ base: "left", md: "left" }}
                    mb={8}
                    px={2}
                >
                    Trusted by organizations including
                </Text>
                <SimpleGrid
                    columns={{ base: 2, md: 5 }}
                    spacing={{ base: 10, md: 12 }}
                    justifyItems="center"
                    alignItems="center"
                    px={2}
                >
                    {data.map(({ logo, name, brightness }, index) => (
                        <Image
                            key={index}
                            src={logo}
                            alt={name || "Company logo"}
                            width={{ base: "130px", md: "150px", lg: "180px" }}
                            height="auto"
                            objectFit="contain"
                            userSelect="none"
                            filter={`grayscale(100%) brightness(${brightness})`}
                            _hover={{ filter: "none" }}
                            transition="filter 0.2s"
                        />
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>
    );
}