import { Box, Text, VStack, Flex, Image, Container, HStack } from "@chakra-ui/react";
import logo from "../assets/icon.png";

export default function Features() {
    return (
        <Container
            maxW={"5xl"}
            py={16}
            mt={"12rem !important"}
            px={{ base: 4, md: 8 }}
        >
            <VStack spacing={"6rem"} alignItems="stretch" width="100%">
                <Box width="100%">
                    <Flex width="100%" textAlign="left" alignItems="center" flexDirection={{ base: "column", sm: "row" }} mb={12}>
                        <Box>
                            <HStack spacing={4}>
                                <Image src={logo} alt="Sweep logo" width={10} />
                                <Text mt={4} fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold">The latest AI features in the IDEs that you love</Text>
                            </HStack>
                            <Text mt={4} fontSize={{ base: "lg", md: "xl" }} color="lightgrey">Sweep brings the latest innovations in AI coding into JetBrains IDEs like IntelliJ, PyCharm, and more.</Text>
                        </Box>
                    </Flex>
                </Box>

                <Box width="100%">
                    <Flex width="100%" textAlign="left" alignItems="center" flexDirection={{ base: "column", sm: "row" }} mb={12}>
                        <Box>
                            <HStack spacing={4}>
                                <Text fontSize="4xl">⚡</Text>
                                <Text mt={4} fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold">Save hours writing the same code over and over</Text>
                            </HStack>
                            <Text mt={4} fontSize={{ base: "lg", md: "xl" }} color="lightgrey">Sweep understands your entire codebase to automate simple tasks like writing tests, fixing bugs, and more.</Text>
                        </Box>
                    </Flex>
                </Box>

                <Box width="100%">
                    <Flex width="100%" textAlign="left" alignItems="center" flexDirection={{ base: "column", sm: "row" }} mb={12}>
                        <Box>
                            <HStack spacing={4}>
                                <Text fontSize="4xl">🚀</Text>
                                <Text mt={4} fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold">Onboard new developers faster</Text>
                            </HStack>
                            <Text mt={4} fontSize={{ base: "lg", md: "xl" }} color="lightgrey">Sweep helps new hires quickly get up to speed with any codebase.</Text>
                        </Box>
                    </Flex>
                </Box>
            </VStack>
        </Container>
    );
}