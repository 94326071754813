import React from 'react';
import { Box, Image, Text, SimpleGrid, VStack, Button, Stack } from '@chakra-ui/react';

interface TeamMemberProps {
  name: string;
  description: string;
  imageSrc: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({
  name,
  description,
  imageSrc
}) => (
  <VStack
    spacing={6}
    alignItems="center"
    p={{ base: 4, md: 8 }}
    borderRadius={10}
    background="rgba(51, 40, 91, 0.8)"
  >
    <Box
      width="200px"
      height="200px"
      borderRadius="xl"
      overflow="hidden"
      mb={4}
    >
      <Image
        src={imageSrc}
        alt={name}
        width="100%"
        height="100%"
        objectFit="cover"
      />
    </Box>
    <Text
      fontSize="2xl"
      fontWeight="semibold"
      color="white"
    >
      {name}
    </Text>
    <Text
      color="gray.300"
      textAlign="center"
      fontSize="xl"
      lineHeight="1.8"
    >
      {description}
    </Text>
  </VStack>
);

const AboutUs: React.FC = () => (
  <Box
    p={8}
    backgroundColor="transparent"
    py={12}
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Stack spacing={6} maxW="4xl" textAlign="center" mb={16}>
      <Text
        fontSize="6xl"
        fontWeight="bold"
      >
        Join the Team
      </Text>
      <Text fontSize="2xl" color="gray.300" px={4}>
        We're a YC-backed startup building the future of AI-powered development tools. Our team brings experience from Roblox, CMU, and cutting-edge ML research.
      </Text>
      <Button
        color="white"
        colorScheme="purple"
        size="lg"
        bg={"purple.700"}
        _hover={{ bg: "purple.500" }}
        px={8}
        width="fit-content"
        alignSelf="center"
        onClick={() => window.open("https://www.ycombinator.com/companies/sweep/jobs/8dUn406-founding-engineer", "_blank")}
      >
        Open Positions
      </Button>
    </Stack>

    <Text
      fontSize="4xl"
      fontWeight="bold"
      mb={8}
    >
      Founders
    </Text>

    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      maxWidth="1600px"
      spacing={16}
      width="100%"
    >
      <TeamMember
        name="William Zeng"
        description="Previously led Search ML at Roblox as the youngest senior MLE, where he built vector search and ranking models serving 70M+ DAU."
        imageSrc={process.env.PUBLIC_URL + '/wz_pfp.png'}
      />
      <TeamMember
        name="Kevin Lu"
        description="Previously worked on Avatar ML at Roblox, including the first ML-based skin weight generation model for UGC avatars."
        imageSrc={process.env.PUBLIC_URL + '/KEVIN.jpeg'}
      />
    </SimpleGrid>
  </Box>
);

export default AboutUs;