import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  VStack,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { FaPlay } from "react-icons/fa";

export default function CallToAction() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };  return (
    <Container maxW={"5xl"} px={{ base: 4, md: 8 }}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 4, md: 15 }}
        style={{ paddingTop: "0 !important" }}
        mt="!important"
      >
        <VStack spacing={8} alignItems="left" width="100%">
          <Box
            width="96px"
            mt="3rem"
            mx="auto"
            display={{ base: "none", lg: "block" }}
          >
            <img src="/icon.png" alt="Sweep Logo" />
          </Box>
          <Heading
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
              lineHeight={"110%"}
              mt="3rem"
              textAlign={"center"}
              width="100%"
          >
            The best AI coding assistant for JetBrains
          </Heading>
          <Text
              fontSize={{ base: "md", sm: "lg", md: "xl" }}
              textAlign={"center"}
              width="100%"
          >
            Accelerate your JetBrains developers with the latest in AI coding intelligence. <br></br> No data ever leaves your VPC.
          </Text>
          <Stack
              direction={{ base: "column", sm: "row" }}
              spacing={4}
              width="100%"
              alignItems={{ base: "stretch", sm: "flex-start" }}
              justify="center"
          >
            <Button
                color="gray.700"
                p={4}
                bg={"gray.100"}
                _hover={{ bg: "white" }}
                onClick={() => window.open("https://form.typeform.com/to/wliuvyWE")}
                fontSize={{ base: "md", md: "lg" }}
                width={{ base: "100%", sm: "300px" }}
            >
              Request Demo
            </Button>
          </Stack>
          <Box maxW="container.xl" mx="auto" my={8} position="relative">
            <video
                ref={videoRef}
                loop
                muted
                playsInline
                controls
                style={{
                  width: '100%',
                  maxWidth: '1200px',
                  borderRadius: '12px',
                  margin: '0 auto',
                }}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
            >
                <source src="/sweep_ai_demo.mp4" type="video/mp4" />
                <img
                    src="/sweep_ai_demo_screenshot.png"
                    alt="Sweep AI Demo"
                    style={{
                        width: '100%',
                        maxWidth: '1200px',
                        borderRadius: '12px',
                        margin: '0 auto',
                    }}
                />
            </video>
            {!isPlaying && (
                <IconButton
                    aria-label="Play video"
                    icon={<FaPlay />}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    size="lg"
                    fontSize="24px"
                    color="white"
                    bg="blackAlpha.600"
                    _hover={{ bg: "blackAlpha.800" }}
                    onClick={togglePlay}
                    isRound
                />
            )}
          </Box>
        </VStack>
      </Stack>
    </Container>
  );
}
