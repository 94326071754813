import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import logo from "../assets/icon.png";

export default function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const listDisplay = useBreakpointValue({ base: "none", lg: "flex" });
  const menuDisplay = useBreakpointValue({ base: "flex", lg: "none" });

  const extItems = [
    {
      label: "About Us",
      link: "/about-us",
      isExternal: false
    },
    {
      label: "Blogs",
      link: "https://docs.sweep.dev/blogs",
      isExternal: true
    },
    {
      label: "Security",
      link: "https://docs.sweep.dev/blogs/soc2",
      isExternal: true
    },
  ]

  return (
    <Box
      as="nav"
      bg="bg-surface"
      width="full"
      p={4}
      position="fixed"
      top={0}
      zIndex={1000}
      backdropFilter="blur(3px)"
      backgroundColor="transparent"
    >
      <Flex justify="center" align="center">
        <HStack spacing={4} justify="center" maxW="1200px" width="full">
          {/* Logo */}
          <Button
            as={Link}
            variant="ghost"
            to="/"
            py={6}
            fontSize="xl"
          >
            <Image src={logo} alt="logo" width={10} borderRadius={12} />
            Sweep AI
          </Button>

          {/* Desktop Navigation */}
          <HStack spacing={4} display={listDisplay} alignItems="center" flex="1" justify="center">
            {extItems.map((item) => {
              return item.isExternal ? (
                <Button
                  key={item.label}
                  as="a"
                  variant="ghost"
                  href={item.link}
                  target="_blank"
                  fontSize="xl"
                  py={6}
                  display="flex"
                  alignItems="center"
                >
                  {item.label}
                </Button>
              ) : (
                <Button
                  key={item.label}
                  as={RouterLink}
                  variant="ghost"
                  to={item.link}
                  fontSize="xl"
                  py={6}
                  display="flex"
                  alignItems="center"
                >
                  {item.label}
                </Button>
              );
            })}
            <Button
              as={RouterLink}
              to="/pricing"
              variant="ghost"
              fontSize="xl"
              py={6}
              display="flex"
              alignItems="center"
            >
              Pricing
            </Button>
            <Button
              color="white"
              colorScheme="purple"
              bg="purple.700"
              _hover={{ bg: "purple.500" }}
              fontSize="lg"
              py={6}
              width="fit-content"
              display="flex"
              alignItems="center"
              onClick={() => window.open("https://form.typeform.com/to/wliuvyWE")}
            >
              Contact Sales
            </Button>
          </HStack>

          {/* Mobile Menu Button */}
          <IconButton
            display={menuDisplay}
            aria-label="Open menu"
            icon={<HamburgerIcon />}
            variant="ghost"
            onClick={onOpen}
          />
        </HStack>
      </Flex>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          minW={{ base: "100%", sm: "100%" }}
          maxW={{ base: "100%", sm: "100%" }}
          bg="gray.900"
          borderLeft="1px solid"
          borderColor="whiteAlpha.200"
        >
          <DrawerCloseButton color="white" />
          <DrawerHeader borderBottomWidth="1px" borderColor="whiteAlpha.200" color="white">
            Sweep AI
          </DrawerHeader>
          <DrawerBody p={4}>
            <VStack
              spacing={4}
              align="stretch"
              mt={4}
              width="100%"
              minW="100%"
            >
              {extItems.map((item) => (
                item.isExternal ? (
                  <Button
                    key={item.label}
                    as="a"
                    href={item.link}
                    target="_blank"
                    variant="ghost"
                    width="100%"
                    minW="100%"
                    justifyContent="flex-start"
                    onClick={onClose}
                    color="white"
                    fontSize="lg"
                    py={6}
                    _hover={{ bg: "whiteAlpha.200" }}
                  >
                    {item.label}
                  </Button>
                ) : (
                  <Button
                    key={item.label}
                    as={RouterLink}
                    to={item.link}
                    variant="ghost"
                    width="100%"
                    minW="100%"
                    justifyContent="flex-start"
                    onClick={onClose}
                    color="white"
                    fontSize="lg"
                    py={6}
                    _hover={{ bg: "whiteAlpha.200" }}
                  >
                    {item.label}
                  </Button>
                )
              ))}
              <Button
                as={RouterLink}
                to="/pricing"
                variant="ghost"
                width="100%"
                minW="100%"
                justifyContent="flex-start"
                onClick={onClose}
                color="white"
                fontSize="lg"
                py={6}
                _hover={{ bg: "whiteAlpha.200" }}
              >
                Pricing
              </Button>
              <Button
                color="white"
                colorScheme="purple"
                bg="purple.700"
                _hover={{ bg: "purple.500" }}
                width="100%"
                minW="100%"
                fontSize="lg"
                py={6}
                onClick={() => {
                  window.open("https://form.typeform.com/to/wliuvyWE");
                  onClose();
                }}
              >
                Contact Sales
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}